import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Vid from "../media/videos/rehabilitation.mp4"
import VidPoster from "../media/videos/rehabilitation_poster.jpg"

// import components
import Layout from "../components/sections/layout"
import Seo from "../components/sections/seo"
import HeaderVideo from "../components/headerVideo"

// import templates
import BasicLayout from "../templates/basic-layout"

const seo_description = `If you are suffering with any long term injury or ailment then Ty Paul is a NHS approved personal trainer. Helping clients to get back to your best possible self`
const seo_keywords = `Rehabilitation, long term injury, private rehabilitation, fitness training, NHS approved, rehabilitation training, PT prices, personal training, recover from illness more efficiently, affordable prices, over 60s club, , personal training Hove, personal training Brighton, online personal training, Hove personal trainer, Best personal trainer Hove, Personal Trainer in Brighton, Personal Trainer in Hove, Personal Trainer Near Me, online personal trainer hove, online personal trainer brighton, PT Hove & Brighton`

const Rehabilitation = () => {
  const data = useStaticQuery(query)
  const { strapiRehabilitation } = data
  const { title, description, SEO } = strapiRehabilitation

  const {
    page_description = seo_description,
    page_keywords = seo_keywords,
    page_title = title,
  } = SEO

  return (
    <Layout>
      <Seo
        title={page_title}
        description={page_description}
        keywords={page_keywords}
      />
      <HeaderVideo src={Vid} poster={VidPoster} title={title} />
      <BasicLayout
        pageName={title}
        description={description}
        rightImage={""}
        images={[]}
      />
      <div className="container">
        <aside className="google-review" style={{ marginBottom: "40px" }}>
          <p className="google-review__title">
            Andy parsons, condition: bronchiectasis
          </p>
          <p className="google-review__description">
            <i>
              “Ty is everything you would want from a personal trainer: he's a
              nice bloke, friendly, enjoyable to work with, very supportive and
              a great trainer that gets results. He's professional and takes
              real pride in his service; on my first session he'd researched my
              medical condition extensively and had produced a fitness plan
              bespoke to me. Consequently my fitness has improved significantly
              after just a few months and I enjoy the workouts every time. He
              really is highly recommended and it was great to see him included
              in a recent Men's Health magazine when they named him one of
              Britain's Best Coaches - he absolutely deserved it.”
            </i>
          </p>
        </aside>
      </div>
    </Layout>
  )
}

const query = graphql`
  {
    strapiRehabilitation {
      SEO {
        page_description
        page_keywords
        page_title
      }
      title
      description
    }
  }
`

export default Rehabilitation
